<template>
    <li :class="open ? ['bg-primary-900'] : ['border-opacity-0']">
        <div @click="open = !open" class="flex justify-between cursor-pointer px-6 py-3 hover:bg-primary-850 anim-ein-fast-all">
            <p class="text-gray-200">
                <fa class="mr-2" v-if="icon" :icon="['fas', icon]" :class="active ? ['text-accent-400'] : ['text-gray-400']" />
                <t :path="name" />
            </p>
            <span class="transition-transform" :class="[active ? ['text-accent-400'] : ['text-white'], open ? ['rotate-90'] : []]">
                <fa class="text-xs" :icon="['fas', `chevron-right`]" />
            </span>
        </div>

        <div v-if="open">
            <slot />
        </div>
    </li>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        name: {
            type: [String, Array],
            required: true,
        },
        icon: {
            type: String,
            required: false,
        },
        active: {
            type: Boolean,
            default: false,
        }
    },
    setup(props) {
        const open = ref(props.active);

        return {
            open
        };
    }
});
</script>

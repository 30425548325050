<template>
    <Disclosure :default-open="true" v-slot="{open}">
        <div class="bg-primary-900 border border-primary-800 bg-clip-content rounded">
            <DisclosureButton :disabled="!disclosure" class="w-full">
            <div class="text-white text-opacity-75 border-b border-primary-800 p-3 sm:px-4">
                <slot name="header">
                    <div class="flex">
                        <div class="flex flex-grow items-center">
                            <h3 class="text-xl text-white font-light">
                                <t v-if="title" :path="title" />
                                <template v-else>
                                    No title specified
                                </template>
                            </h3>
                                <slot name="container-header-extra" />
                            <!-- TODO: description isn't centered vertically -->
                            <p v-if="description" class="ml-2 text-white text-opacity-50">
                                <!-- TODO: Investigate why this causes an error (switch from any page to databases to re-produce) -->
                                <!--<skeleton :content="8">-->
                                <t :path="formattedDescription" />
                                <!--</skeleton>-->
                            </p>
                            <div class="ml-auto">
                                <fa v-if="disclosure" class="transition-transform delay-100" :icon="['fas', 'arrow-down']" :rotation="open ? 180 : null" />
                            </div>
                        </div>

                        <slot name="actions" />
                    </div>
                </slot>
            </div>
        </DisclosureButton>
        <DisclosurePanel>
            <div :class="!noPadding ? ['p-3 sm:p-6'] : []">
                <slot />
            </div>
            </DisclosurePanel>
        </div>
    </Disclosure>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  } from '@headlessui/vue'

export default defineComponent({
    props: {
        title: {
            type: [String, Array],
        },
        description: {
            type: [String, Array],
        },
        noPadding: {
            type: Boolean
        },
        disclosure: {
            type: Boolean
        }
    },

    components: {
        Disclosure,
        DisclosureButton,
        DisclosurePanel
    },

    setup(props) {
        return {
            formattedDescription: computed(() => Array.isArray(props.description) ? props.description : [props.description, {}])
        };
    }
});
</script>

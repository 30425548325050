<template>
    <footer>
        <div class="relative w-full text-center py-5">
            <fa :icon="['fas', 'code-branch']" /> {{ version }}
        </div>
    </footer>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
    setup(props, context) {
        return {
            version: computed(() => `${window.Physgun.Node || 'unknown'} - ${window.Physgun.Version || 'unknown'}`),
        };
    }
});
</script>

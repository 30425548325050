import RequestService from './request';

interface workshopGenerateParams {
    collectionId: string;
}

class FastdlService {
    sync(): Promise<void> {
        return RequestService.post('/servers/:server/fastdl');
    }

    workshopGenerate(data: workshopGenerateParams): Promise<any> {
        return RequestService.post('/servers/:server/fastdl/workshop', data);
    }
}

export default new FastdlService();

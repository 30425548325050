<template>
    <img v-if="avatarURL" class="outline-none" alt="user image" :src="avatarURL">
    <div v-else class="skeleton outline-none" />
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { md5 } from 'hash-wasm';

export default defineComponent({
    props: {
        email: {
            type: String,
            required: true,
        },
    },

    setup(props) {
        const avatarURL = ref<null | string>(null);

        const setAvatarURL = async (email?: string) => {
            let hash = 'physgun is the best hosting company :D';

            if (email) {
                hash = await md5(email);
            }

            avatarURL.value = `https://gravatar.com/avatar/${hash}`
        };

        setAvatarURL(props.email);
        watch(() => props.email, value => setAvatarURL(value));

        return {
            avatarURL,
        };
    },
});
</script>
